<template>
  <v-container fluid no-gutters>
    <B2cTabs />

    <div class="titles mt-6">Preferences</div>
    <v-card outlined class="mb-8" :style="cardStyle">
      <v-card-text>
        <v-row class="mt-5">
          <v-col cols="12">
            <p class="mb-0 pl-4 pt-4 sub_titles">
              <strong>Modules</strong>
            </p>
          </v-col></v-row
        >
        <v-row>
          <v-col
            lg="2 "
            md="2"
            class="ml-4"
            v-for="qube_module in qube_modules"
            :key="qube_module.name"
          >
            <v-checkbox
              v-model="configurations.qube_modules[qube_module.name]"
              class="mt-2"
              false-value="0"
              true-value="1"
              :label="qube_module.label"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="4">
            <p class="mb-0 pl-4 pt-4 sub_titles">
              <strong>Primary Colour</strong>
              &nbsp; &nbsp;
              <v-icon @click="resetColors" color="#000">mdi-reload</v-icon>
            </p>
            <v-card-text class="">
              <v-card
                class="rounded-lg ml-3"
                elevation="0"
                @click="dialogPrimary = true"
                :color="configurations.app_colors.primary"
                style="width: 25%; height: 50px"
              >
              </v-card>
            </v-card-text>
            <v-dialog v-model="dialogPrimary" persistent max-width="290">
              <v-card>
                <v-card-title class="text-h5">
                  Select primary color
                </v-card-title>
                <v-card-text>
                  <v-color-picker
                    v-model="configurations.app_colors.primary"
                    mode="hexa"
                  ></v-color-picker>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <!-- <v-btn color="red" text @click="dialogPrimary = false">
                      Cancel
                    </v-btn> -->
                  <v-btn
                    color="green darken-1"
                    outlined
                    @click="dialogPrimary = false"
                  >
                    Done
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="4">
            <p class="mb-0 pl-4 pt-4 sub_titles">
              <strong>Facility Tab Layout</strong>
            </p>

            <v-card-text class="">
              <v-card rounded outlined class="pa-4 mt-0">
                <!-- <v-row>
                  <v-col md="12" class=""> -->
                <v-radio-group
                  v-model="configurations.qube_app_layout_based_on"
                  row
                >
                  <v-radio
                    label="Service based"
                    color="primary darken-3"
                    value="service"
                  ></v-radio>
                  <v-radio
                    label="Facility based"
                    color="primary darken-3"
                    value="facility"
                  ></v-radio>
                </v-radio-group>
                <!-- </v-col>
                </v-row> -->
              </v-card>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="6">
            <p class="mb-0 pl-4 pt-4 sub_titles">
              <strong>Filter Options</strong>
            </p>
          </v-col></v-row
        >
        <v-row>
          <v-col lg="2" md="2" class="ml-4">
            <v-checkbox
              v-model="configurations.enable_time_duration"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Duration"
            ></v-checkbox>
          </v-col>
          <v-col lg="2" md="2" class="ml-4">
            <v-checkbox
              v-model="configurations.enable_game_formation"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Game Formation"
            ></v-checkbox>
          </v-col>
          <v-col lg="2" md="2" class="ml-4">
            <v-checkbox
              v-model="configurations.enable_ground_surface"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Surface"
            ></v-checkbox>
          </v-col>
          <v-col lg="2" md="" class="ml-4">
            <v-checkbox
              v-model="configurations.enable_ground_types"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Indoor/Outdoor"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-row>
          <v-col lg="2" md="2" class="ml-4" v-if="venue_enable_qpoints == '1'">
            <v-checkbox
              v-model="configurations.enable_qpoints"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Enable QPoints"
            ></v-checkbox>
          </v-col>

          <v-col lg="2" md="2" class="ml-4">
            <v-checkbox
              v-model="configurations.enable_customer_tags"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Enable Customer Tags"
            ></v-checkbox>
          </v-col>
          <v-col
            lg="2"
            md="2"
            class="ml-4"
            v-if="venue_enable_split_payment == '1'"
          >
            <v-checkbox
              v-model="configurations.enable_split_payment"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Enable Split Payment"
            ></v-checkbox>
          </v-col>

          <v-col
            lg="3"
            md="3"
            class="ml-4"
            v-if="
              configurations.enable_split_payment === '1' &&
              venue_enable_split_payment == '1'
            "
          >
            <v-autocomplete
              :items="venueServices"
              v-model="configurations.splitPaymentEnabledVenueServicesIds"
              item-value="venue_service_id"
              item-text="name"
              label="Service"
              outlined
              multiple
              background-color="#fff"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggleSelect()">
                  <v-list-item-action>
                    <v-icon
                      :color="
                        configurations.splitPaymentEnabledVenueServicesIds
                          .length > 0
                          ? 'indigo darken-4'
                          : ''
                      "
                      >{{ getIcon() }}</v-icon
                    >
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>All</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template
                v-if="
                  configurations.splitPaymentEnabledVenueServicesIds.length ==
                  venueServices.length
                "
                v-slot:selection="{ index }"
              >
                <span v-if="index == 0">All Services</span>
              </template>
              <template v-else v-slot:selection="{ item, index }">
                <span v-if="index == 0">{{ item.name }}</span>
                <span v-if="index == 1">, {{ item.name }}</span>
                <span v-if="index == 2">, ...</span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col lg="3" md="3" class="ml-4">
            <v-checkbox
              v-model="configurations.enable_custom_booking_date_range"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Enable Custom Booking Date range"
            ></v-checkbox>
          </v-col>

          <v-col
            lg="3"
            md="3"
            v-if="configurations.enable_custom_booking_date_range === '1'"
          >
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  background-color="#fff"
                  class="q-text-field shadow-0"
                  hide-details
                  dense
                  v-model="date1Formatted"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="configurations.custom_booking_start_date"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            lg="3"
            md="3"
            v-if="configurations.enable_custom_booking_date_range === '1'"
          >
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  class="q-text-field shadow-0"
                  hide-details
                  dense
                  v-model="date2Formatted"
                  outlined
                  background-color="#fff"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="configurations.custom_booking_end_date"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-base font-semibold black-text ml-1 mt-6">
      User Registration Configurations
    </div>
    <v-card class="mb-8 rounded-2 bordered shadow-0 mt-2">
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <table class="table text-center table-bordered">
              <thead>
                <tr
                  class="opacity-70 tr-neon tr-rounded text-center font-bold black-text"
                >
                  <th class="text-center">Field Name</th>
                  <th class="text-center">View</th>
                  <th class="text-center">Mandatory</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(config, index) in configurations.field_configurations"
                  :key="index"
                >
                  <td
                    class="black-text font-bold text-sm"
                    style="font-weight: 500 !important"
                  >
                    {{ config.name }}
                  </td>
                  <td>
                    <v-btn
                      icon
                      :style="
                        viewRestrictedFields.includes(config.slug) &&
                        'cursor:not-allowed;'
                      "
                      @click="checkAsVisible(index)"
                    >
                      <TickIcon v-if="config.is_visible == 1" />
                      <CloseIcon v-else />
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      icon
                      @click="checkAsMandatory(index)"
                      :style="
                        mandatoryRestrictedFields.includes(config.slug) &&
                        'cursor:not-allowed;'
                      "
                    >
                      <TickIcon v-if="config.is_required == 1" />
                      <CloseIcon v-else />
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="ma-2 white--text teal-color"
        text
        height="45"
        @click="submitData()"
        >Save</v-btn
      >
    </v-card-actions>
  </v-container>
</template>
<script>
import moment from "moment";
import B2cTabs from "@/views/B2CConfigurations/B2cTabs.vue";
import TickIcon from "@/assets/images/misc/config-table-tick-icon.svg";
import CloseIcon from "@/assets/images/misc/config-table-close-icon.svg";
import constants from "@/utils/constants";
export default {
  components: { CloseIcon, TickIcon, B2cTabs },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    this.btnShow = true;
    this.getConfigurations();
    this.getVenueProfileDetails();
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data.filter(
        (service) => service.name != "POS"
      );
    },
    date1Formatted() {
      return moment(
        this.configurations.custom_booking_start_date,
        "YYYY-MM-DD"
      ).format("Do MMM YYYY");
    },
    date2Formatted() {
      return moment(
        this.configurations.custom_booking_end_date,
        "YYYY-MM-DD"
      ).format("Do MMM YYYY");
    },
  },
  data() {
    return {
      btnShow: false,
      defaultColors: {
        app_colors: {
          primary: "#007FFF",
        },
      },
      viewRestrictedFields: constants.VIEWRESTRICTEDFIELDS,
      mandatoryRestrictedFields: constants.MANTATORYRESTRICTEDFIELDS,
      dialogPrimary: false,
      menu1: null,
      menu2: null,
      venue_enable_split_payment: false,
      venue_enable_qpoints: false,
      configurations: {
        field_configurations: [],
        custom_booking_start_date: moment().format("YYYY-MM-DD"),
        custom_booking_end_date: moment().add(14, "days").format("YYYY-MM-DD"),
        enable_fb_reschedule: "0",
        enable_trainer_reschedule: "0",
        enable_academy_reschedule: "0",
        enable_event_reschedule: "0",
        enable_fb_participant_details: "0",
        show_time_range_filter: "0",
        show_facility_image: "0",
        enable_time_capacity_filter: "0",
        hide_qube_membership_price: "0",
        capacity_filter_title: null,
        time_filter_title: null,
        enable_time_duration: "0",
        enable_game_formation: "0",
        enable_ground_surface: "0",
        enable_ground_types: "0",
        qube_app_layout_based_on: "service",
        enable_split_payment: false,
        enable_qpoints: false,
        enable_customer_tags: false,
        is_app: true,
        enable_custom_booking_date_range: false,
        splitPaymentEnabledVenueServicesIds: [],
        qube_modules: {
          facility: 0,
          trainer: 0,
          academy: 0,
          event: 0,
          pos: 0,
        },
        app_colors: {
          primary: "#007FFF",
        },
      },
      have_both_time_capacity_services: true,
      qube_modules: [
        { name: "facility", label: "Facility" },
        { name: "trainer", label: "Trainer" },
        { name: "academy", label: "Academy" },
        { name: "event", label: "Event" },
        { name: "pos", label: "Retail" },
      ],
    };
  },
  methods: {
    checkAsMandatory(index) {
      let field = this.configurations.field_configurations[index];
      if (!this.mandatoryRestrictedFields.includes(field.slug)) {
        this.configurations.field_configurations[index].is_required =
          field.is_required == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configurations.field_configurations[index].is_visible = 1;
        }
      }
    },
    checkAsVisible(index) {
      let field = this.configurations.field_configurations[index];
      if (!this.viewRestrictedFields.includes(field.slug)) {
        this.configurations.field_configurations[index].is_visible =
          field.is_visible == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configurations.field_configurations[index].is_required = 0;
        }
      }
    },
    getVenueProfileDetails() {
      this.$store.dispatch("loadVenueProfileDetails").then(() => {
        const data = this.$store.getters.getVenueDetails.data;
        this.venue_enable_split_payment = data.enable_split_payment
          ? data.enable_split_payment
          : 0;
        this.venue_enable_qpoints = data.enable_qpoints
          ? data.enable_qpoints
          : 0;
      });
    },
    toggleSelect() {
      this.$nextTick(() => {
        if (
          this.venueServices.length ==
          this.configurations.splitPaymentEnabledVenueServicesIds.length
        ) {
          this.configurations.splitPaymentEnabledVenueServicesIds = [];
        } else {
          this.configurations.splitPaymentEnabledVenueServicesIds =
            this.venueServices.map((item) => item.venue_service_id);
        }
      });
    },
    getIcon() {
      if (this.configurations.splitPaymentEnabledVenueServicesIds.length == 0)
        return "mdi-checkbox-blank-outline";
      if (
        this.venueServices.length ==
        this.configurations.splitPaymentEnabledVenueServicesIds.length
      )
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    getConfigurations() {
      this.showLoader("Loading");

      this.$http
        .get("venues/b2c/bookings-configurations?is_app=1")
        .then((response) => {
          if (response.status == 200) {
            let data = response.data.data;
            if (data.enable_fb_reschedule) {
              this.configurations.enable_fb_reschedule =
                data.enable_fb_reschedule.toString();
            } else {
              this.configurations.enable_fb_reschedule = "0";
            }
            if (data.enable_trainer_reschedule) {
              this.configurations.enable_trainer_reschedule =
                data.enable_trainer_reschedule.toString();
            } else {
              this.configurations.enable_trainer_reschedule = "0";
            }
            if (data.enable_academy_reschedule) {
              this.configurations.enable_academy_reschedule =
                data.enable_academy_reschedule.toString();
            } else {
              this.configurations.enable_academy_reschedule = "0";
            }
            if (data.enable_event_reschedule) {
              this.configurations.enable_event_reschedule =
                data.enable_event_reschedule.toString();
            } else {
              this.configurations.enable_event_reschedule = "0";
            }
            if (data.enable_fb_participant_details) {
              this.configurations.enable_fb_participant_details =
                data.enable_fb_participant_details.toString();
            } else {
              this.configurations.enable_fb_participant_details = "0";
            }
            if (data.show_facility_image) {
              this.configurations.show_facility_image =
                data.show_facility_image.toString();
            } else {
              this.configurations.show_facility_image = "0";
            }
            if (data.show_time_range_filter) {
              this.configurations.show_time_range_filter =
                data.show_time_range_filter.toString();
            } else {
              this.configurations.show_time_range_filter = "0";
            }
            if (data.enable_time_capacity_filter) {
              this.configurations.enable_time_capacity_filter =
                data.enable_time_capacity_filter.toString();
            } else {
              this.configurations.enable_time_capacity_filter = "0";
            }
            if (data.hide_qube_membership_price) {
              this.configurations.hide_qube_membership_price =
                data.hide_qube_membership_price.toString();
            } else {
              this.configurations.hide_qube_membership_price = "0";
            }
            if (data.enable_time_duration) {
              this.configurations.enable_time_duration =
                data.enable_time_duration.toString();
            } else {
              this.configurations.enable_time_duration = "0";
            }

            if (data.enable_game_formation) {
              this.configurations.enable_game_formation =
                data.enable_game_formation.toString();
            } else {
              this.configurations.enable_game_formation = "0";
            }

            if (data.enable_ground_surface) {
              this.configurations.enable_ground_surface =
                data.enable_ground_surface.toString();
            } else {
              this.configurations.enable_ground_surface = "0";
            }

            if (data.enable_ground_types) {
              this.configurations.enable_ground_types =
                data.enable_ground_types.toString();
            } else {
              this.configurations.enable_ground_types = "0";
            }
            if (data.qube_app_layout_based_on) {
              this.configurations.qube_app_layout_based_on =
                data.qube_app_layout_based_on;
            } else {
              this.configurations.qube_app_layout_based_on = "service";
            }
            if (data.enable_split_payment) {
              this.configurations.enable_split_payment =
                data.enable_split_payment.toString();
            } else {
              this.configurations.enable_split_payment = "0";
            }
            if (data.enable_qpoints) {
              this.configurations.enable_qpoints =
                data.enable_qpoints.toString();
            } else {
              this.configurations.enable_qpoints = "0";
            }

            if (data.enable_customer_tags) {
              this.configurations.enable_customer_tags =
                data.enable_customer_tags.toString();
            } else {
              this.configurations.enable_customer_tags = "0";
            }
            if (data.venue_services.length > 0) {
              this.configurations.splitPaymentEnabledVenueServicesIds =
                data.venue_services
                  .filter((item) => item.enable_split_payment == 1)
                  .map((item) => item.id);
            }
            this.configurations.capacity_filter_title =
              data.capacity_filter_title;
            this.configurations.time_filter_title = data.time_filter_title;

            this.have_both_time_capacity_services =
              response.data.have_both_time_capacity_services;

            const qubeModules = data.qube_modules;
            if (Array.isArray(qubeModules)) {
              this.updateQubeModules(qubeModules);
            } else {
              this.resetQubeModulesToDefault();
            }
            if (data.app_colors) {
              let appColors = JSON.parse(data.app_colors);
              if (appColors.primary) {
                this.configurations.app_colors.primary = appColors.primary;
              } else {
                this.configurations.app_colors.primary =
                  this.defaultColors.app_colors.primary;
              }
            }
            if (data.enable_custom_booking_date_range) {
              this.configurations.enable_custom_booking_date_range =
                data.enable_custom_booking_date_range.toString();
            } else {
              this.configurations.enable_custom_booking_date_range = "0";
            }
            if (
              data.custom_booking_start_date &&
              data.custom_booking_start_date !== "0000-00-00"
            ) {
              this.configurations.custom_booking_start_date =
                data.custom_booking_start_date.toString();
            }
            if (
              data.custom_booking_end_date &&
              data.custom_booking_end_date != "0000-00-00"
            ) {
              this.configurations.custom_booking_end_date =
                data.custom_booking_end_date.toString();
            }
            this.configurations.field_configurations =
              data.field_configurations;
            // this.configurations.enable_fb_reschedule =
            //   data.enable_fb_reschedule;
            // this.configurations.enable_trainer_reschedule =
            //   data.enable_trainer_reschedule;
            // this.configurations.enable_academy_reschedule =
            //   data.enable_academy_reschedule;
            // this.configurations.enable_event_reschedule =
            //   data.enable_event_reschedule;
            this.hideLoader();
          }
        })
        .catch((error) => {
          console.log(error);
          this.hideLoader();
        });
    },
    updateQubeModules(modules) {
      // Reset to default before updating
      this.resetQubeModulesToDefault();

      modules.forEach((module) => {
        if (
          module.product_type.toLowerCase() in this.configurations.qube_modules
        ) {
          this.configurations.qube_modules[module.product_type.toLowerCase()] =
            module.status_id !== 1 ? "0" : "1";
        }
      });
    },
    resetQubeModulesToDefault() {
      // Resetting to default values
      this.configurations.qube_modules = {
        facility: 0,
        trainer: 0,
        academy: 0,
        event: 0,
        pos: 0,
      };
    },

    submitData() {
      this.showLoader("Loading");

      let data = this.configurations;

      this.$http
        .post(`venues/b2c/bookings-configurations`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Configuration saved successfully.");
            this.getConfigurations();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
      this.hideLoader();
    },
    resetColors() {
      this.configurations.app_colors = this.defaultColors.app_colors;
    },
  },
};
</script>

<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
</style>
